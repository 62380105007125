import { defu } from 'defu';
import type { UseFetchOptions } from 'nuxt/app';
import type { ZodSchema } from 'zod';

/**
 * Тип параметров функции обращения к бэкенду
 */
export type UseApiOptions<T> = UseFetchOptions<T> & {
  model?: ZodSchema;
};

/**
 * Общая функция запроса данных с бэкенда в API слое проекта
 */
export function useApi<T>(url: string | (() => string), options: UseApiOptions<T> = {}) {
  const { public: publicConfig } = useRuntimeConfig();
  const headers = useRequestHeaders(['cookie', 'x-mocks', 'x-mocks-partial']);

  const defaults: UseFetchOptions<T> = {
    baseURL: publicConfig.apiBaseUrl,
    headers,
    /**
     * Хук на обработку каждого ответа
     */
    async onResponse(ctx) {
      // Выполнение редиректа, если бэк ответил редиректом
      if (ctx.response.status === 302) {
        await navigateTo(ctx.response.url);
        return;
      }

      if (options.model) {
        const parsed = options.model.safeParse(ctx.response._data.data);

        if (parsed.success) {
          ctx.response._data = parsed.data;
        } else {
          console.error({
            url,
            error: parsed.error,
            model: options.model,
            data: ctx.response._data,
          });
        }
      }

      // Сохраняем возможность использовать этот хук позже
      if (typeof options.onResponse === 'function') {
        await options.onResponse(ctx);
      }
    },
    /**
     * Хук на ошибку ответа
     */
    onResponseError(ctx) {
      // TODO: Кинуть уведомление
      // Сохраняем возможность использовать этот хук позже
      if (typeof options.onResponseError === 'function') {
        options.onResponseError(ctx);
      }
    },
    onRequest(ctx) {
      if (typeof options?.onRequest === 'function') {
        options.onRequest(ctx);
      }
    },
  };

  // Объединение параметров. Unjs/defu — внутренняя зависимость Nuxt
  const params = defu(options, defaults);

  return useFetch(url, params);
}
