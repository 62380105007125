/**
 * Статус корректного ответа
 */
export const API_STATUS_OK = 'ok';

/**
 * Статус ответа с ошибкой
 */
export const API_STATUS_ERROR = 'error';

/**
 * Версия эндпоинтов API по умолчанию
 */
export const API_DEFAULT_VERSION = 2;
