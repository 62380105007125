/**
 * Добавляет версию в api endpoint
 *
 * @param endpoint - полный URL запроса
 * @param apiVersion - версия api
 * @param routeVersion - принудительно сделать URL с нужной версией для конкретного запроса
 */
export function versioned(endpoint: string, apiVersion?: number, routeVersion?: number) {
  const withVersion = routeVersion ?? apiVersion;
  // TODO: refactor default version to get it here
  return `/v${withVersion}${endpoint}`;
}
