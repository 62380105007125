import { z } from 'zod';

/**
 * Доступные в приложении валюты
 */
export const AVAILABLE_COINS = ['USDT', 'BTC', 'TON'] as const;

/**
 * Модель аккаунта пользователя
 */
export const modelUserAccount = z.object({
  /**
   * Валюта
   */
  coin: z.enum(AVAILABLE_COINS),
  /**
   * Баланс в крипте
   * TODO: добавить валидацию по regex
   */
  balance: z.string(),
  /**
   * Баланс в фиатной валюте
   * TODO: добавить валидацию по regex
   */
  balanceFiat: z.string(),
});

/**
 * Описание типа одного счета монеты с балансами
 */
export type ModelUserAccount = z.infer<typeof modelUserAccount>;

/**
 * Модель ответа со всеми аккаунтами пользователя
 */
export const modelUserAllAccountsResponse = z.object({
  /**
   * Суммарный баланс в фиатной валюте
   */
  totalFiat: z.string(),
  /**
   * Список валют
   */
  items: z.array(modelUserAccount),
});

export type ModelUserAllAccountsResponse = z.infer<typeof modelUserAllAccountsResponse>;
